import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import { background, backgroundInverted } from 'styles/config/_colors.scss'

const query = graphql`
  query {
    prismic {
      site_settings(uid: "site-settings", lang: "en-us") {
        site_meta_title
        site_meta_description
        site_meta_thumbnail
      }
    }
  }
`

const SEO = ({ title, description, thumbnail, schema, lang, lightTheme }) => (
  <StaticQuery
    query={`${query}`} // workaround proptype error: https://github.com/birkir/gatsby-source-prismic-graphql/issues/70#issuecomment-572779682
    render={(data) => {
      const settings = data.prismic.site_settings
      const metaTitle = `Myriad | ${title || settings.site_meta_title}`
      const metaDescription = description || settings.site_meta_description
      const metaThumbnail = thumbnail || settings.site_meta_thumbnail
      const metaThumbnailUrl = metaThumbnail ? metaThumbnail.url : { url: '' }
      const pageSchema = schema || ''

      let themeColor = background
      if (lightTheme) themeColor = backgroundInverted

      return (
        <Helmet
          htmlAttributes={{
            lang,
          }}
          title={metaTitle}
          meta={[
            {
              name: `description`,
              content: metaDescription,
            },
            {
              property: `og:title`,
              content: metaTitle,
            },
            {
              property: `og:description`,
              content: metaDescription,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:image`,
              content: metaThumbnailUrl,
            },
            {
              name: `twitter:card`,
              content: `summary_large_image`,
            },
            {
              name: `twitter:title`,
              content: metaTitle,
            },
            {
              name: `twitter:description`,
              content: metaDescription,
            },
            {
              name: `twitter:image`,
              content: metaThumbnailUrl,
            },
            {
              name: 'theme-color',
              content: themeColor,
            },
          ]}
          encodeSpecialCharacters={true}
        >
          {pageSchema}
        </Helmet>
      )
    }}
  />
)

SEO.defaultProps = {
  lang: 'en',
  schema: '',
}

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.object,
  schema: PropTypes.string,
  lang: PropTypes.string,
  lightTheme: PropTypes.bool,
}

export default SEO
